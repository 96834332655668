import api from "../api";

const EventsService = {
  getAllEvents: async () => {
    try {
      const response = await api.get("/event/list");
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch events.");
    }
  },

  getEvent: async (eventId) => {
    try {
      const response = await api.get(`/event/${eventId}`);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch events.");
    }
  },

  deleteEvent: async (eventId) => {
    try {
      await api.delete(`/event/${eventId}`);
    } catch (error) {
      throw new Error("Failed to delete event.");
    }
  },

  duplicateEvent: async (eventId) => {
    try {
      await api.post(`/event/${eventId}/duplicate`);
    } catch (error) {
      throw new Error("Failed to duplicate event.");
    }
  },

  startEvent: async (eventId) => {
    try {
      await api.post(`/event/${eventId}/start`);
    } catch (error) {
      throw new Error("Failed to start event.");
    }
  },

  exportEvent: async (eventId) => {
    try {
      const response = await api.get(`/event/${eventId}/export`, {
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      throw new Error("Failed to export event.");
    }
  },

  updateEventActions: async (eventId, actions) => {
    try {
      await api.post(`/event/${eventId}/actions`, { actions });
    } catch (error) {
      throw new Error("Failed to update event actions.");
    }
  },
  createEvent: async (name) => {
    try {
      const response = await api.post("/event/create", { name });
      return response.data;
    } catch (error) {
      throw new Error("Failed to create event.");
    }
  },
  addTimepoint: async (eventId, type, time) => {
    try {
      await api.post(`/event/${eventId}/timepoint`, { type, time });
    } catch (error) {
      throw new Error("Failed to add timepoint.");
    }
  },

  getTimeArray: async (eventId) => {
    try {
      const response = await api.get(`/event/${eventId}/timearray`);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch time array.");
    }
  },
};

export default EventsService;
