import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EventsService from "../services/EventsService";

function MyEventsPage() {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchEvents = async () => {
    try {
      setLoading(true);
      const data = await EventsService.getAllEvents();
      setEvents(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleAction = async (action, eventId) => {
    try {
      if (action === "duplicate") {
        await EventsService.duplicateEvent(eventId);
      } else if (action === "delete") {
        await EventsService.deleteEvent(eventId);
      }
      fetchEvents(); // Refresh events after action
    } catch (err) {
      alert(err.message);
    }
  };

  const handleExport = async (eventId) => {
    try {
      const data = await EventsService.exportEvent(eventId);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `event_${eventId}.json`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      alert(err.message);
    }
  };

  const handleEventClick = (event) => {
    if (event.started) {
      navigate(`/event/${event.id}`);
    } else {
      navigate(`/event/${event.id}/step2`);
    }
  };

  return (
    <>
      <div className="page-header">
        <nav className="navbar navbar-expand-lg d-flex justify-content-between">
          <div className="header-title flex-fill">
            <h5>My Events</h5>
          </div>
        </nav>
      </div>
      <div className="main-wrapper">
        {loading && <p>Loading...</p>}
        {error && <p className="text-danger">{error}</p>}
        {!loading && events.length === 0 && (
          <p className="text-muted">No events found.</p>
        )}
        <div className="row">
          {events.map((event) => (
            <div className="col-lg-4" key={event.id}>
              <div className={`card event-card widget widget-info card-bg ${
                        event.started ? "bg-dark" : ""
                      }`}>
                <div className="card-body r b">
                  <h5
                    className="card-title"
                    onClick={() => handleEventClick(event)}
                    style={{ cursor: "pointer" }}
                  >
                    {event.name}
                    <span
                    style={{margin:"0 8px"}}
                      className={`badge ${
                        event.started ? "bg-success" : "bg-warning"
                      }`}
                    >
                      {event.started ? "Started" : "Not Started"}
                    </span>
                  </h5>

                  <div className="btn-group mt-3">
                    <button
                      className="btn btn-sm btn-info"
                      onClick={() => handleAction("duplicate", event.id)}
                    >
                      Duplicate
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => handleAction("delete", event.id)}
                    >
                      Delete
                    </button>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => handleExport(event.id)}
                    >
                      Export
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default MyEventsPage;
