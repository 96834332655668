import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import EventsService from "../services/EventsService";
import api from "../api";
import { TimelineSelector } from "../components/TimelineSelector";

function EventPage() {
  const { id: eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [timeArray, setTimeArray] = useState([]);
  const [actionCounts, setActionCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const [averages, setAverages] = useState([]);
  const [error, setError] = useState(null);
  const [connected, setConnected] = useState(false);

  const fetchEventDetails = useCallback(async () => {
    try {
      const eventDetails = await EventsService.getEvent(eventId);
      setEvent(eventDetails);
    } catch (error) {
      console.error(error.message);
      alert("Failed to fetch event details.");
    }
  }, [eventId]);

  const handleRefreshAveragesClick = () => {
    calculateAverages(timeArray, event);
  };

  const calculateAverages = (timeArray, event) => {
    const actionGroups = {};
    const averages = [];

    // Group timepoints by actionId
    timeArray.forEach((timepoint) => {
      if (!actionGroups[timepoint.actionId]) {
        actionGroups[timepoint.actionId] = [];
      }
      actionGroups[timepoint.actionId].push(new Date(timepoint.time).getTime());
    });

    // Calculate averages for the same action
    Object.keys(actionGroups).forEach((actionId) => {
      const times = actionGroups[actionId].sort((a, b) => a - b);
      const intervals = times.slice(1).map((t, i) => t - times[i]);

      const averageInterval = intervals.length
        ? intervals.reduce((a, b) => a + b, 0) / intervals.length
        : 0;

      const minInterval = intervals.length ? Math.min(...intervals) : 0;
      const maxInterval = intervals.length ? Math.max(...intervals) : 0;

      averages.push({
        label: `"${
          event.actions.find((a) => a.id === actionId)?.name || actionId
        }" <-> "${
          event.actions.find((a) => a.id === actionId)?.name || actionId
        }"`,
        value: averageInterval
          ? `${(averageInterval / 1000).toFixed(2)}s`
          : "N/A",
        minValue: minInterval ? `${(minInterval / 1000).toFixed(2)}s` : "N/A",
        maxValue: maxInterval ? `${(maxInterval / 1000).toFixed(2)}s` : "N/A",
      });
    });

    // Calculate averages, min, and max between different actions
    for (let i = 0; i < event.actions.length; i++) {
      for (let j = i + 1; j < event.actions.length; j++) {
        const action1 = event.actions[i];
        const action2 = event.actions[j];
        const times1 = actionGroups[action1.id] || [];
        const times2 = actionGroups[action2.id] || [];
        const combined = [
          ...times1.map((t) => ({ time: t, type: 1 })),
          ...times2.map((t) => ({ time: t, type: 2 })),
        ];
        combined.sort((a, b) => a.time - b.time);

        const intervals = [];
        let minInterval = Infinity;
        let maxInterval = -Infinity;
        for (let k = 1; k < combined.length; k++) {
          if (combined[k - 1].type !== combined[k].type) {
            const interval = combined[k].time - combined[k - 1].time;
            intervals.push(interval);
            if (interval < minInterval) minInterval = interval;
            if (interval > maxInterval) maxInterval = interval;
          }
        }

        const averageInterval = intervals.length
          ? intervals.reduce((a, b) => a + b, 0) / intervals.length
          : 0;

        averages.push({
          label: `"${action1.name}" <-> "${action2.name}"`,
          value: averageInterval
            ? `${(averageInterval / 1000).toFixed(2)}s`
            : "N/A",
          minValue:
            minInterval !== Infinity
              ? `${(minInterval / 1000).toFixed(2)}s`
              : "N/A",
          maxValue:
            maxInterval !== -Infinity
              ? `${(maxInterval / 1000).toFixed(2)}s`
              : "N/A",
        });
      }
    }

    setAverages(averages);
  };

  const fetchTimeArray = useCallback(async () => {
    try {
      const timeArrayData = await EventsService.getTimeArray(eventId);
      setTimeArray(timeArrayData);

      // Recalculate action counts
      const counts = timeArrayData.reduce((acc, entry) => {
        acc[entry.actionId] = (acc[entry.actionId] || 0) + 1;
        return acc;
      }, {});
      setActionCounts(counts);
    } catch (error) {
      console.error(error.message);
      alert("Failed to fetch time array.");
    }
  }, [eventId]);

  const handleActionClick = async (typeId, time) => {
    try {
      await api.post(`/event/${eventId}/timepoint`, {
        type: typeId,
        time: time,
      });

      // Refresh the time array and update the counts
      await fetchTimeArray(calculateAverages);
      calculateAverages(timeArray, event);
    } catch (err) {
      setError(`Failed to record action: ${time}`);
    }
  };

  useEffect(() => {
    const initializePage = async () => {
      setLoading(true);
      await fetchEventDetails();
      await fetchTimeArray();
      setLoading(false);
    };

    initializePage();
  }, [fetchEventDetails, fetchTimeArray, eventId]);

  const serviceUuid = "4fafc201-1fb5-459e-8fcc-c5c9c331914b";
  const characteristicUuid = "beb5483e-36e1-4688-b7f5-ea07361b26a8";

  const handleConnectClick = async () => {
    try {
      let bluetoothDevice = await navigator.bluetooth.requestDevice({
        filters: [{ name: "FishikaBLE" }],
        optionalServices: [serviceUuid],
      });
      //setBluetoothDevice(bluetoothDevice);

      const server = await bluetoothDevice.gatt.connect();

      const service = await server.getPrimaryService(serviceUuid);

      let characteristic = await service.getCharacteristic(characteristicUuid);
      //setCharacteristic(characteristic);

      characteristic.addEventListener(
        "characteristicvaluechanged",
        handleNotifications
      );
      await characteristic.startNotifications();
      setConnected(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNotifications = (event) => {
    const value = new TextDecoder().decode(event.target.value);
    const valueObj = JSON.parse(value);
    handleActionClick(valueObj.type, Math.floor(Date.now() / 1000));
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p style={{ color: "red" }}>{error}</p>;
  }

  if (!event) {
    return <p>No event found.</p>;
  }

  return (
    <>
      <div className="page-header">
        <nav className="navbar navbar-expand-lg d-flex justify-content-between">
          <div className="header-title flex-fill">
            <h5>
              My Events {">"} Event: {event.name}
            </h5>
          </div>
        </nav>
      </div>
      <div className="main-wrapper">
        {!connected && (
          <button
            className="btn btn-primary mb-3 btn-lg"
            style={{ marginRight: "16px" }}
            onClick={handleConnectClick}
          >
            <i class="fab fa-bluetooth-b" style={{ marginRight: "8px" }}></i>{" "}
            Connect to KronixBT
          </button>
        )}

        {averages.length === 0 && (
          <button
            className="btn btn-info mb-3 btn-lg"
            onClick={handleRefreshAveragesClick}
          >
            <i class="fas fa-sync" style={{ marginRight: "8px" }}></i> Refresh
            Averages
          </button>
        )}
        <div className="row">
          <div className="card event-card widget widget-info col-md-6">
            <div className="card-body r card-bg">
              <h5 className="text-white">Event ID: </h5>
              <p>{event.id}</p>
              <h5 className="text-white">Status: </h5>
              <p>
                <span
                  className={`badge ${
                    event.started ? "bg-success" : "bg-warning"
                  }`}
                >
                  {event.started ? "Started" : "Not Started"}
                </span>
              </p>
              <h5 className="text-white">Created At: </h5>
              <p>{new Date(event.createdAt).toLocaleString()}</p>
              <h5 className="text-white">Updated At: </h5>
              <p>{new Date(event.updatedAt).toLocaleString()}</p>
            </div>
          </div>
          <div
            className="card event-card widget widget-info col-md-6"
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="card-body r card-bg">
              <h2>Counter</h2>
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                {event.actions.map((action) => (
                  <button
                    key={action.id}
                    onClick={() =>
                      handleActionClick(
                        action.typeId,
                        Math.floor(Date.now() / 1000)
                      )
                    }
                    style={{
                      padding: "20px",
                      fontSize: "24px",
                      borderRadius: "5px",
                      backgroundColor: "#007BFF",
                      color: "#FFF",
                      border: "none",
                      cursor: "pointer",
                      width: "160px",
                      height: "160px",
                    }}
                  >
                    {action.name} {actionCounts[action.id] || 0}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <h2>Averages</h2>
        <div
          style={{
            display: "grid",
            gap: "10px",
            gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          }}
        >
          {averages.map((average, index) => (
            <div key={index} className="card event-card widget widget-info">
              <div className="card-body r card-bg">
                <p style={{ margin: 0, fontWeight: "bold" }}>{average.label}</p>
                <p style={{ margin: 0 }}>Average: {average.value}</p>
                {average.minValue !== "N/A" && (
                  <p style={{ margin: 0, color: "red" }}>
                    Min: {average.minValue}
                  </p>
                )}
                {average.maxValue !== "N/A" && (
                  <p style={{ margin: 0, color: "green" }}>
                    Max: {average.maxValue}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>

        <TimelineSelector timeArray={timeArray} actions={event.actions} />

        <h2>Timepoints</h2>
        {timeArray.length > 0 ? (
          <ul>
            {timeArray.map((timepoint, index) => {
              // Find the action corresponding to the actionId
              const action = event.actions.find(
                (action) => action.id === timepoint.actionId
              );

              return (
                <li key={index}>
                  Action: {action ? action.name : "Unknown"}, Time:{" "}
                  {new Date(timepoint.time).toLocaleString()}
                </li>
              );
            })}
          </ul>
        ) : (
          <p>No timepoints recorded yet.</p>
        )}
      </div>
    </>
  );
}

export default EventPage;
