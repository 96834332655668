import React, { useState } from "react";
import AuthService from "../services/AuthService";

function ResetPasswordPage() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage(null);
    setError(null);

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      await AuthService.resetPassword(newPassword);
      setMessage("Your password has been reset successfully.");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
        <div className="login-page">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-12 col-lg-4">
                <div className="card login-box-container">
                  <div className="card-body">
                    <div className="authent-text"><h1>Reset Password</h1>
                      <p>Enter your new password below.</p>
                    </div>
                    <form onSubmit={handleResetPassword}>
                      <div className="mb-3">
                        <div className="form-floating">
                          <input
                            type="password"
                            className="form-control"
                            id="floatingNewPassword"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                          <label htmlFor="floatingNewPassword">
                            New Password
                          </label>
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="form-floating">
                          <input
                            type="password"
                            className="form-control"
                            id="floatingConfirmPassword"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <label htmlFor="floatingConfirmPassword">
                            Confirm Password
                          </label>
                        </div>
                      </div>
                      <div className="d-grid">
                        <button type="submit" className="btn btn-info m-b-xs">
                          Reset Password
                        </button>
                      </div>
                    </form>
                    {message && (
                      <div className="authent-text" style={{ color: "green" }}>
                        {message}
                      </div>
                    )}
                    {error && (
                      <div className="authent-text" style={{ color: "red" }}>
                        {error}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
}

export default ResetPasswordPage;
