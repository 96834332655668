/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import CreateEventPage from "./pages/CreateEventPage";
import EventPage from "./pages/EventPage";
import Step2Page from "./pages/Step2Page";
import MyEventsPage from "./pages/MyEventsPage";

function App() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };
  return (
    <div class="page-container">
      <div class="page-sidebar">
        <a class="logo" href="/">
          <img
            src={"/logo-kronix.png"}
            alt="Kronix Logo"
            style={{ maxWidth: "100%", padding: "0 40px" }}
          />
        </a>

        <ul class="list-unstyled accordion-menu">
          <li class="active-page">
            <a class="active">
              Events
              <i class="fas fa-chevron-down dropdown-icon"></i>
            </a>
            <ul class="">
              <li>
                <a
                  href="#"
                  class="active"
                  onClick={() => navigate("/my-events")}
                >
                  <i class="far fa-calendar-alt"></i>My Events
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="active"
                  onClick={() => navigate("/create-event")}
                >
                  <i class="far fa-calendar-plus"></i>Create Event
                </a>
              </li>
            </ul>
          </li>
          <li class="active-page">
            <a href="#" class="active">
              Account
              <i class="fas fa-chevron-down dropdown-icon"></i>
            </a>
            <ul class="">
              <li>
                <a
                  href="#"
                  onClick={() => navigate("/reset-password")}
                  class="active"
                >
                  <i class="fas fa-key"></i>Reset Password
                </a>
              </li>
              <li>
                <a href="#" class="active" onClick={handleLogout}>
                  <i class="fas fa-sign-out-alt"></i>Logout
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="page-content">
        <Routes>
          <Route
            path="/"
            element={token ? <HomePage /> : <Navigate to="/login" />}
          />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/reset-password"
            element={token ? <ResetPasswordPage /> : <Navigate to="/login" />}
          />
          <Route
            path="/create-event"
            element={token ? <CreateEventPage /> : <Navigate to="/login" />}
          />
          <Route
            path="/event/:id"
            element={token ? <EventPage /> : <Navigate to="/login" />}
          />
          <Route
            path="/event/:id/step2"
            element={token ? <Step2Page /> : <Navigate to="/login" />}
          />
          <Route
            path="/my-events"
            element={token ? <MyEventsPage /> : <Navigate to="/login" />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
