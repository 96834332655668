import React, { useState, useEffect } from "react";
import EventsService from "../services/EventsService";
import { useNavigate, useParams } from "react-router-dom";

const actionsGPIOMap = [18,5,19,21]

function Step2Page() {
  const { id: eventId } = useParams();
  const [eventName, setEventName] = useState("");
  const [actions, setActions] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const event = await EventsService.getEvent(eventId);
        setEventName(event.name);
        setActions(event.actions || []);
        if (event.started) {
          navigate(`/event/${eventId}`);
        }
      } catch (err) {
        setError("Failed to load event.");
      }
    };

    fetchEvent();
  }, [eventId, navigate]);

  const handleAddAction = () => {
    if (actions.length < 4) {
      setActions([...actions, { name: "", typeId: "" }]);
    }
  };

  const handleRemoveAction = (index) => {
    setActions(actions.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, field, value) => {
    const updatedActions = [...actions];
    updatedActions[index][field] = value;
    setActions(updatedActions);
  };

  const handleUpdateActions = async () => {
    try {
      await EventsService.updateEventActions(eventId, actions);
      alert("Actions updated successfully!");
    } catch (err) {
      setError("Failed to update actions.");
    }
  };

  const handleStartEvent = async () => {
    try {
      await EventsService.startEvent(eventId);
      navigate(`/event/${eventId}`);
    } catch (err) {
      setError("Failed to start event.");
    }
  };

  return (
    <>
      {" "}
      <div className="page-header">
        <nav className="navbar navbar-expand-lg d-flex justify-content-between">
          <div className="header-title flex-fill">
            <h5>Configure Actions for {eventName}</h5>
          </div>
        </nav>
      </div>
      <div className="main-wrapper">
        <h5 style={{ color: "green" }}>Step 2</h5>
        <div className="progress m-b-sm">
          <div
            className="progress-bar progress-bar-striped bg-success"
            role="progressbar"
            style={{ width: "80%" }}
            aria-valuenow="80"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <h3>Actions:</h3>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <div
          className="mt-3"
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "16px",
          }}
        >
          {actions.map((action, index) => (
            <div
              key={index}
              style={{
                marginBottom: "16px",
                display: "flex",
                flexDirection: "column",
                maxWidth: "50%",
                alignItems: "center",
                gap: "16px",
                float: "left",
              }}
            >
              <img
                src={`/button${index + 1}.png`}
                alt="button1"
                style={{ maxWidth: "100%" }}
              />
              <input
                type="text"
                placeholder="Action Label"
                className="form-control"
                value={action.name}
                onChange={(e) => {
                  handleInputChange(index, "name", e.target.value);
                  handleInputChange(index, "typeId", actionsGPIOMap[index]);
                }}
              />
              <button
                className="btn btn-outline-danger"
                style={{ width: "100%" }}
                onClick={() => handleRemoveAction(index)}
              >
                Remove
              </button>
            </div>
          ))}
        </div>
        {actions.length < 4 && (
          <button onClick={handleAddAction} className="btn btn-success">
            Add Action
          </button>
        )}
        <div style={{ marginTop: "24px" }}>
          <div className="alert alert-warning light-alert" role="alert">
            <i class="fas fa-exclamation" style={{ marginRight: "8px" }}></i>
            Event not started, in order to start tracking, ensure you configured
            the actions as needed and Start the event!
          </div>
          <div className="alert alert-danger light-alert" role="alert">
            <i class="fas fa-exclamation" style={{ marginRight: "8px" }}></i>
            (ACTIONS CANNOT BE CHANGED AFTER STARTING THE EVENT)
          </div>
          <button onClick={handleUpdateActions} className="btn btn-info btn-lg">
            Update Actions
          </button>
          <button
            onClick={handleStartEvent}
            className="btn btn-primary btn-lg"
            style={{ marginLeft: "8px" }}
          >
            Start Event
          </button>
        </div>
      </div>
    </>
  );
}

export default Step2Page;
