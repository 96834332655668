import api from '../api';

const AuthService = {
  resetPassword: async (email) => {
    try {
      await api.post('/auth/reset-password', { email });
    } catch (error) {
      throw new Error('Failed to send reset password link. Please try again.');
    }
  },
  login: async (deviceId, key) => {
    try {
      const response = await api.post('/auth/login', { deviceId, key });
      const token = response.data.access_token;
      localStorage.setItem('token', token);
      return token;
    } catch (error) {
      throw new Error('Invalid credentials. Please try again.');
    }
  },
};

export default AuthService;
