import React, { useState } from "react";
import EventsService from "../services/EventsService";
import { useNavigate } from "react-router-dom";

function CreateEventPage() {
  const [eventName, setEventName] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleCreateEvent = async () => {
    if (!eventName.trim()) {
      setError("Event name cannot be empty.");
      return;
    }

    try {
      const newEvent = await EventsService.createEvent(eventName);
      navigate(`/event/${newEvent.id}/step2`);
    } catch (err) {
      setError("Failed to create event. Please try again.");
    }
  };

  const handleCancel = () => {
    navigate("/my-events");
  };

  return (
    <>
      <div className="page-header">
        <nav className="navbar navbar-expand-lg d-flex justify-content-between">
          <div className="header-title flex-fill">
            <h5>Create Event</h5>
          </div>
        </nav>
      </div>
      <div className="main-wrapper">
        <h5 style={{color:'green'}}>Step 1</h5>
        <div className="progress m-b-sm">
          <div
            className="progress-bar progress-bar-striped bg-success"
            role="progressbar"
            style={{ width: "25%" }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        {error && <p style={{ color: "red" }}>{error}</p>}

        <div className="form-floating">
          <input
            type="text"
            placeholder="Enter Event Name"
            className="form-control"
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
          />
          <label>Event Name</label>
        </div>
        <div style={{ marginTop: "16px" }}>
          <button className="btn btn-primary btn-lg" onClick={handleCreateEvent}>Next</button>
          <button className="btn btn-danger btn-lg" onClick={handleCancel} style={{ marginLeft: "8px" }}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default CreateEventPage;
