import React from "react";
import {jwtDecode} from "jwt-decode";

function HomePage() {
  const token = localStorage.getItem("token");
  let username = "";

  if (token) {
    try {
      const decoded = jwtDecode(token); // Decode the token
      console.log(decoded, "decoded");
      username = decoded.username; // Extract userId
    } catch (error) {
      console.error("Invalid token:", error.message);
    }
  }

  return (
    <>
      <div className="page-header">
        <nav className="navbar navbar-expand-lg d-flex justify-content-between">
          <div className="header-title flex-fill">
            <h5>Dashboard</h5>
          </div>
        </nav>
      </div>
      <div className="main-wrapper">
        <p>Device ID: {username || "Unknown"}</p>
      </div>
    </>
  );
}

export default HomePage;
