import React, { useState } from 'react';
import AuthService from '../services/AuthService';

function LoginPage() {
  const [deviceId, setDeviceId] = useState('');
  const [key, setKey] = useState('');
  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null); // Clear previous error
    try {
      await AuthService.login(deviceId, key);
      window.location.href = '/'; // Redirect after successful login
    } catch (err) {
      setError(err.message); // Display error message
    }
  };

  return (
    <div className="login-page">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-12 col-lg-4">
            <div className="card login-box-container">
              <div className="card-body">
                <div className="authent-logo">
                  <img src={'./logo-kronix.png'} alt="Kronix Logo" style={{ maxWidth: '100%' }} />
                </div>
                <div className="authent-text">
                  <p>Please Sign-in to your account.</p>
                </div>
                <form onSubmit={handleLogin}>
                  <div className="mb-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingDeviceId"
                        placeholder="Device ID"
                        value={deviceId}
                        onChange={(e) => setDeviceId(e.target.value)}
                      />
                      <label htmlFor="floatingDeviceId">Device ID</label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-floating">
                      <input
                        type="password"
                        className="form-control"
                        id="floatingPassword"
                        placeholder="Passphrase"
                        value={key}
                        onChange={(e) => setKey(e.target.value)}
                      />
                      <label htmlFor="floatingPassword">Passphrase</label>
                    </div>
                  </div>
                  <div className="d-grid">
                    <button type="submit" className="btn btn-info m-b-xs">
                      Sign In
                    </button>
                  </div>
                </form>
                {error && (
                  <div className="authent-text" style={{ color: 'red' }}>
                    {error}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
